import React from 'react';
import "./LogoutButton.css";

const LogoutButton = (props) => (
	<a id="logout-button" href={props.base + '?mc.u=a'} title={props.title}>
		{props.content}
	</a>
);

export default LogoutButton;
