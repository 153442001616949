/**
 * 
 */
'use strict';

import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import UserMenuContainer from './containers/UserMenuContainer';

const e = React.createElement;

const userMenuDiv = document.querySelector('#user-menu');

if (userMenuDiv) {
	const base = userMenuDiv.querySelector('#base').innerHTML;
	const userName = userMenuDiv.querySelector('#userName').innerHTML;
	const logoutTitle = userMenuDiv.querySelector('#logoutTitle').innerHTML;
	const languageChooserTitle = userMenuDiv.querySelector('#languageChooserTitle').innerHTML;
	const userMenuContainer = <UserMenuContainer
									base={base}
									userName={userName}
									logoutTitle={logoutTitle}
									languageChooserTitle={languageChooserTitle}
								/>;
	ReactDOM.render(userMenuContainer, userMenuDiv);
}

//const content = Text2Img(logoutDiv.querySelector('#content').innerHTML);
/**
 * take text2img tag an create react element.
 * 
 * @param content
 *            tag
 * @returns element or content if no img tag was found
 */
function Text2Img(content) {
	if (!content.includes('img')) {
		return content;
	}
	let arr = content.split(' ');
	let attributes = {};
	for (let i = 0; i < arr.length; i++) {
		const att = arr[i];
		if (att.includes('img')) {
			continue;
		}
		const att_array = att.split('=');
		Object.assign(attributes, { [att_array[0]]: ('' + att_array[1]).replace(/"/g,'') });
	}
	return e('img', attributes, null);
}
