import React from 'react';

/* <a class="startpage"
 *  href="{$contextprefix}?newlanguage=de_DE"
 *   style="margin:0px;padding:0px;margin-right:8px;" >
 *   	<img src="{$contextprefix}/images/flag_de.gif" alt="deutsch" border="0"/>
 *   </a> 
 */
const LanguageButton = (props) => (
	<a href={props.base + '?newlanguage=' + props.short + '_' + props.short.toUpperCase()}
		className={'language-button startpage' + props.alt === 'en' ? '2' : ''}
		title={props.title ? props.title : ''} >
			<img src={props.base + '/images/flag_' + props.short + '.gif'} alt={props.alt} border="0"/>
	</a>
);

export default LanguageButton;
