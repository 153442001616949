import React from 'react';
import LogoutButton from '../buttons/LogoutButton';
import LanguageButton from '../buttons/LanguageButton';


class UserMenuContainer extends React.Component {

	constructor(props) {
	    super(props);
	    this.state = {
	    	expanded : false
	    };
	}

	render() {
        return (
            <div className="user-menu">
                <UserButton
                    userName={this.props.userName.replace(/ , Benutzername.*$/, "")}
					onClick={() => this.toggleExpanded()}
					expanded={this.state.expanded}
                />
                { this.state.expanded &&
                    <div className="user-submenu">
                        <LanguageButtonChooser
                            base={this.props.base}
                            title={this.props.languageChooserTitle}
                        />
                        <div className="user-settings" >
                            <a href="global/config/show">
                                    Einstellungen
                            </a>
                        </div>
                        <LogoutButton
                            base={this.props.base}
                            title={this.props.logoutTitle}
                            content={this.props.logoutTitle}
                        />
                    </div>
                }
            </div>
        );
    }

	toggleExpanded() {
		this.setState({
			expanded : !this.state.expanded
		});
	}

}

export default UserMenuContainer;

function UserButton(props) {
	return (
		<button onClick={props.onClick} className="user-button">
		    <i className="fa fa-user"></i>
			{props.userName}
			{props.expanded ?
				<i className="fa fa-chevron-up"></i>
			:
				<i className="fa fa-chevron-down"></i>
			}
		</button>
	);
}

function LanguageButtonChooser(props) {
	return (
		<div className="language-menu">
			<div className="title">
				{props.title}
			</div>
			<div className="language-selection">
				<LanguageButton short="de" alt="deutsch" base={props.base} />
				<LanguageButton short="en" alt="english" base={props.base} />
			</div>
		</div>
	);
}
